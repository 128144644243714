//@ts-check
import React, { Component, Fragment } from "react";
import {
  getCart,
  getShipping,
  getBilling,
  createOrder,
  removeFromCart,
  getSetting,
} from "./../services/apiService";
import { calcPrice2, toFixed } from "../services/utils";
import { connect } from "react-redux";
import {
  updateCartAction,
  updateSettingsAction,
  resetOrdersAction,
} from "../actions";
import { formatMoney } from "./../services/utils";
import { showAlert } from "../components/dialogs";
import { CONSTS } from "./../constants";

const customer = { name: "", company: "", state: "", city: "", zip: "" };
const initial = {
  customer: customer,
  shipping: customer,
  items: [],
  slabs: [],
};
class OrderDetail extends Component {
  constructor(props) {
    super(props);
    this.taxRate = 0.0725;
    this.state = {
      loading: true,
      order: initial,
      invoice: null,
      error: false,
    };
    this.updateCart = this.updateCart.bind(this);
  }

  async componentDidMount() {
    const cart = getCart();
    //this.props.dispatch(updateCartAction(cart));
    const customer = getBilling();
    const shipping = getShipping();

    if (!this.props.taxRate) await this.getTaxRate();

    if (this.props.taxRate) {
      this.taxRate = customer.tax_exempted ? 0 : this.props.taxRate;
      this.updateCart(cart, customer, shipping);
    } else {
      this.setState({ error: true });
    }
    this.hideSpinner();
  }

  getTaxRate = async () => {
    const result = await getSetting("tax_rate");
    if (result) {
      const value = Number(result.value);
      const taxRate = { taxRate: value };
      this.props.dispatch(updateSettingsAction(taxRate));
      return value;
    }
  };

  getTotalSum = (itemlist) => {
    const total = Number(
      toFixed(
        itemlist.reduce(
          (acumulator, item) => (acumulator += Number(item.amount)),
          0
        )
      )
    );
    return total;
  };

  getSubTotal = (slabs) => {
    const total = Number(
      toFixed(
        slabs.reduce((acumulator, item) => (acumulator += calcPrice2(item)), 0)
      )
    );
    return total;
  };

  updateCart(cart, customer, shipping) {
    const slabs = cart.items.map((item, index) => {
      return {
        index: index,
        bundle_price: item.bundle_price,
        height: item.height,
        slab_id: item.id,
        length: item.length,
        material: item.material,
        slab_num: item.slab_num,
        bundle_num: item.bundle_num,
        unit_price: item.unit_price,
        sqft: item.sqft,
        isbundle: item.isbundle,
        total: calcPrice2(item),
        defected: false,
      };
    });

    const discounts = cart.discounts || [];
    const fees = cart.fees || [];
    const discountSum = this.getTotalSum(discounts);
    const feesSum = this.getTotalSum(fees);
    const subtotal = this.getSubTotal(slabs);
    const dicountedTotal = subtotal - discountSum + feesSum;
    const totalTax = Number(toFixed(dicountedTotal * this.taxRate));
    const totalFees = feesSum;
    const total = dicountedTotal + totalTax;

    const order = {
      subtotal: subtotal,
      tax: totalTax,
      tax_rate: this.taxRate,
      total_fees: totalFees,
      total: total,
      slabs: slabs,
      discounts: discounts,
      fees: fees,
      total_discounts: discountSum,
      customer: customer,
      shipping: shipping,
      customer_id: customer.id,
    };

    this.setState({ order });
  }

  handleCheckout = async () => {
    const order = this.state.order;
    if (!order) return;

    this.showSpinner();

    order["created_by"] = localStorage.getItem("user_id");
    order["sales_rep"] = this.props.profile.nickname;
    const res = await createOrder(order);

    if (!res.error) {
      removeFromCart(-1);
      this.props.dispatch(updateCartAction([]));
      this.props.dispatch(resetOrdersAction());
      localStorage.setItem("cart", "");
      this.setState({ order: initial });
      this.setState({ invoice: res });
      showAlert("Order complete", "success");
    } else {
      showAlert(res.message, "error", 0);
    }

    this.hideSpinner();
  };

  showSpinner = () => {
    this.setState({ loading: true });
  };

  hideSpinner = () => {
    this.setState({ loading: false });
  };

  showInvoice = () => {
    console.log(this.state.invoice);
  };

  render() {
    const cartItems = this.props.cart.items || [];
    return (
      <div className="page-div">
        <div className="container pb-3">
          {this.state.loading && <div className="spinner"></div>}
          <div className="row">
            {cartItems.length > 0 ? (
              <Fragment>
                <div className="col-md-8">
                  <OrderTable {...this.state.order} />
                </div>
                <div className="col-md-4">
                  <CheckoutBox {...this.state} checkout={this.handleCheckout} />
                </div>
              </Fragment>
            ) : (
              <div className="text-center text-secondary">
                <h5>cart is empty</h5>
                {this.state.invoice && (
                  <a
                    className="btn btn-ouline-primary mt-5"
                    href={
                      CONSTS.pdfServer + "vpdf/order/" + this.state.invoice.uid
                    }
                  >
                    Show Invoice
                  </a>
                )}
              </div>
            )}
          </div>
          {cartItems.length > 0 && (
            <div className="row">
              <div className="col-md-4 card-light">
                <CustomerInfo customer={this.state.order.customer} />
              </div>
              <div
                className="col-md-4 card-light"
                style={{ border: "solid 1px #dfdfdf" }}
              >
                <ShippingInfo shipping={this.state.order.shipping} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cart: state.cart,
  profile: state.profile,
  taxRate: state.settings.taxRate,
});

export default connect(mapStateToProps)(OrderDetail);

const OrderTable = (order) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Description</th>
          <th scope="col">Unit Price</th>
          <th scope="col">Total</th>
        </tr>
      </thead>
      <tbody>
        {order.slabs.map((item, index) => (
          <OrderItem key={index} item={item} index={index} />
        ))}
      </tbody>
    </table>
  );
};

const OrderItem = ({ item, index }) => {
  return (
    <tr key={item.id}>
      <th scope="row">{index + 1}</th>
      <td>
        <div>
          <span className="mt-0 font-weight-bold ">{item.material}</span>
          <span className="ml-2">{item.slab_num}</span>
          <div>
            <span className="ml-2">{item.sqft} ft&sup2; </span>
            <span className="mt-0">
              ({toFixed(item.length)} &times; {toFixed(item.height)})
            </span>
          </div>
          <div></div>
        </div>
      </td>
      <td>
        <div>
          {item.isbundle ? (
            <div>{toFixed(item.bundle_price)} *</div>
          ) : (
            <div>{toFixed(item.unit_price)}</div>
          )}
        </div>
      </td>
      <td>
        <div>
          <div className="ml-2">{formatMoney(item.total)}</div>
        </div>
      </td>
    </tr>
  );
};

const CustomerInfo = ({ customer }) => {
  return (
    <div className="p-3 text-secondary">
      <strong>Customer</strong>
      <div>{customer.company}</div>
      <div>{customer.street}</div>
      <span>{customer.city + " " + customer.state + " " + customer.zip} </span>
    </div>
  );
};

const ShippingInfo = ({ shipping }) => {
  return (
    <div className="p-3 text-secondary">
      <strong>Shipping</strong>
      <div>{shipping.name}</div>
      <div>{shipping.street}</div>
      <span>{shipping.city + " " + shipping.state + " " + shipping.zip} </span>
    </div>
  );
};

const CheckoutBox = (props) => {
  const {
    subtotal,
    tax,
    tax_rate,
    total_fees,
    total,
    total_discounts,
  } = props.order;
  return (
    <div className="card">
      <div className="card-header">
        <div className="text-center">
          <button
            className="btn btn-outline-danger"
            disabled={props.error || props.loading}
            onClick={props.checkout}
          >
            Place order
          </button>
        </div>
      </div>
      <div className="p-3 text-weight-bold">Order Summary</div>
      <div className="card-body">
        <table className="table">
          <tbody>
            <tr className="">
              <th scope="row pr-5">Subtotal</th>
              <td className="text-right pl-5">{formatMoney(subtotal)}</td>
            </tr>
            {total_discounts > 0 && (
              <tr className="">
                <th scope="row pr-5">Discounts</th>
                <td className="text-right pl-5">
                  -{formatMoney(total_discounts)}
                </td>
              </tr>
            )}
            {total_fees > 0 && (
              <tr className="">
                <th>Fees</th>
                <td className="text-right">{formatMoney(total_fees)}</td>
              </tr>
            )}
            <tr>
              <th scope="row">Tax ({toFixed(tax_rate * 100)}%)</th>
              <td className="text-right">{formatMoney(tax)}</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr className="table-total">
              <th className="">Order Total </th>
              <th className="text-right">{formatMoney(total)}</th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
