/*eslint no-unused-vars: "off"*/

const isLocalhost = window.location.host.indexOf("localhost") !== -1;
const _server = !isLocalhost
  ? "https://dev.graniteworldutah.com/api/"
  : "http://localhost/api/";
const _imgBaseUrl = !isLocalhost
  ? "https://dev.graniteworldutah.com/slabs/"
  : "http://localhost/slabs/";

export const CONSTS = {
  server: _server,
  imgBaseUrl: _imgBaseUrl,
  pdfServer: "https://leads2.strongrockpavers.com/",
  GOOGLE_API_KEY: "AIzaSyClSHh-fvp4oJBJGZMzu22ccPz71INeLrE",
  GOOGLE_MAPS_API:
    "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=",
  FCM_GROUP: isLocalhost ? "test" : "acct",
  DEFAULT_CENTER: { lat: 40.234047, lng: -111.697087 },
};

export const imageNoFoundName = "/images/no_image.png";
export const imageNoFoundNameW = "/images/no_image_wide.png";
export const PROMO_DATES = "promoDates";
